<template>
  <a-form layout="horizontal" :form="form">
    <a-form-item label="配置Key">
      {{formItem.mapName}}
    </a-form-item>
    <a-form-item label="配置Value" required>
      <a-radio-group v-if="formItem.valueType==='Boolean'"
                     v-decorator="['mapValue',{rules:[{type:'boolean',required:true,message:'配置Value不能为空'}]}]">
        <a-radio :value="true">true</a-radio>
        <a-radio :value="false">false</a-radio>
      </a-radio-group>
      <a-textarea v-if="formItem.valueType==='HTML' || formItem.valueType==='String'"
                  :autosize="{ minRows: 3, maxRows: 6 }"
                  v-decorator="['mapValue',{rules:[{type:'string',required:true,message:'配置Value不能为空'}]}]"
                  placeholder="请输入配置Value"></a-textarea>
      <a-input-number v-if="formItem.valueType==='BigDecimal'" style="width: 200px"
                      :step="0.1"
                      v-decorator="['mapValue', {rules:[
                {type:'number',required:true,message:'配置Value必须为数字'}
                ],trigger:'change'}]"
                      placeholder="输入配置Value"></a-input-number>
    </a-form-item>
    <a-form-item label="描述">
      <a-textarea :autosize="{ minRows: 3, maxRows: 6 }"
                  v-decorator="['description',{rules:[{type:'string',max:255,message:'配置描述不能超过255字符'}]}]"
                  placeholder="请输入配置描述"></a-textarea>
    </a-form-item>
  </a-form>
</template>

<script>

  import SERVICE_URLS from '../../../api/service.url'
  import modalConfirmLoading from '../../common/mixins/modal-confirm-loading'

  export default {
    name: 'PramsForm',
    props: {
      editItem: {
        type: Object
      }
    },
    mixins: [modalConfirmLoading],
    data () {
      return {
        form: this.$form.createForm(this),
        formItem: {
          mapName: '',
          mapValue: '',
          description: '',
          valueType: '',
          id: ''
        },
      }
    },
    watch: {
      editItem () {
        this.setFields(this.editItem)
      }
    },
    methods: {
      setFields (values) {
        Object.assign(this.formItem, values)
        this.$nextTick(() => {
          this.form.setFieldsValue({
            mapValue: this.formItem.mapValue,
            description: this.formItem.description,
          })
        })
      },
      setVOFields (values) {
        this.formItem.mapValue = values.mapValue
        this.formItem.description = values.description
      },
      submitEdit () {
        const {
          form: { validateFields }
        } = this
        validateFields(['mapValue', 'description'], { force: true }, (err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$emit('submitStart')
            this.$http(this, {
              url: SERVICE_URLS.system.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              success: (data) => {
                this.$emit('editSuccess', data.body)
              },
              error: () => {
                this.$emit('submitError')
              }
            })
          }
        })
      }
    }
  }
</script>